import React from 'react';

import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const Immobilier = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.IMMOBILIER}>
      <h1>Immobilier</h1>

      {genererFilAriane('immobilier')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="immobilier"
          src="/img/competences_sprite.jpg"
          alt="Immobilier"
        />
      </div>

      <p>
        Il se peut que des mésententes surviennent après l'achat d'une nouvelle
        résidence.
      </p>
      <p>
        Il peut s'agir de demandes d'annulation de vente, diminution du prix de
        vente, troubles de voisinage, problèmes de limites de propriété ou de
        mitoyenneté, etc.
      </p>
      <p>
        Dans tous ces cas, nos services vous seront utiles pour bien défendre
        vos droits, et ce, de la meilleure façon.
      </p>
    </CompetencesContainer>
  );
};

Immobilier.propTypes = {};

export default Immobilier;
