import React from 'react';

import { PAGES } from '../../utils/utils';
import Immobilier from '../../components/fr/competences/immobilier';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ImmobilierPage = () => (
  <Layout page={PAGES.COMPETENCES_IMMOBILIER}>
    <SEO title="Immobilier" />
    <Immobilier />
  </Layout>
);

export default ImmobilierPage;
